.portfolio-img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 16 / 9;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0%;
    transition: opacity 0.5s;
}

.overlay:hover {
    opacity: 100%;
    transition: opacity 0.5s;
}

.portfolio-link {
    color: #8fa3d7;
    transition: color 0.3s;
}

.portfolio-link:hover {
    color: #b0c6ff;
    transition: color 0.3s;
}

.icon-link {
    font-size: 30px;
}

.portfolio-desc {
    color: #8fa3d7;
}
